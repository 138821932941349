import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ModernComponent} from './layouts/modern/modern.component'

export const routes: Routes = [
    {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
    },
    {
        path: 'modern',
        component: ModernComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // preloadingStrategy: PreloadAllModules,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
