import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-modern-services',
    templateUrl: './modern-services.component.html',
    styleUrls: ['./modern-services.component.scss']
})
export class ModernServicesComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    services = [
        {
            img: "📅",  // Represents spontaneous dates & events
            title: "Instant Dates & Events",
            description: "Connect with people nearby for spontaneous dates or explore exciting events happening around you."
        },
        {
            img: "💬",  // Represents direct messaging
            title: "Direct Messaging",
            description: "Skip the endless swiping—message people directly and start building real connections immediately."
        },
        {
            img: "🛡️",  // Represents safety and AI verification
            title: "Verified and Safe",
            description: "Feel secure with AI-powered profile verification and human moderation, ensuring a safe environment for all users."
        },
        {
            img: "🎯",  // Represents meeting people with shared interests
            title: "Shared Interests",
            description: "Find people who share your hobbies, passions, and values, whether it's sports, music, or food."
        },
        {
            img: "🌟",  // Represents the app being free
            title: "Completely Free",
            description: "Enjoy all features without any cost. Hooked lets you connect and explore with no hidden fees."
        },
        {
            img: "📍",  // Represents location-based meetups
            title: "Local Meetups",
            description: "Discover people and spontaneous meetups in your neighborhood, making it easy to connect locally."
        }
    ];


}
