<!--header start-->
<section class="app2 header overflow-unset" id="home">
    <div class="app2-header bg">
        <div class="container">
            <div class="row">
                <div class="col-xl-7 col-md-6">
                    <div class="center-text">
                        <div>
                            <h6 class="header-top-line">Hooked</h6>
                            <div class="header-text">
                                <h1>Hooked: <span class="bold-text">Spontaneous Dates & Events</span></h1>
                            </div>
                            <div class="header-sub-text">
                                <p class="text-white">Connect instantly with real people near you. Whether it’s a
                                    spontaneous date or local event, Hooked makes meeting new people easy, and fun.
                                    Download now and explore your city with genuine connections.</p>
                            </div>
                            <div class="link-horizontal">
                                <ul>
                                    <li>
                                        <a href="https://apps.apple.com/us/app/hooked-instant-dates-events/id6502847520"
                                           class="btn btn-default btn-white">get app
                                            now (iOS)</a>
                                    </li>
                                    <li>
                                        <a class="btn btn-default primary-btn transparent">get app
                                            now (Android)</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-md-6">
                    <div class="img-mobile set-abs">
                        <img src="../assets/images/app_landing2/h2-mobile.png" alt="" class="headaer-image">

                    </div>
                    <div class="wave-orange"></div>
                </div>
            </div>
        </div>
        <div class="wave"></div>
    </div>
</section>
<!--header end-->