<!--copyright css start-->
<div class="app2 copyright" [ngStyle]="{  'background-image':
        'linear-gradient(to bottom, #3F5EFB, #3F5EFB), url(assets/images/app_landing2/l-2-footer.png)',
'background-repeat': 'no-repeat',
'background-position': 'top',
'background-size': 'cover'
}">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="center-content">
                    <div>
                        <h6 class="copyright-text text-white">© 2024 ByteAffinity LLC. All Rights Reserved.</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- copyright css end-->
<app-tap-to-top></app-tap-to-top>