<!--about start-->
<section class="app2 about format">
    <div class="animated-bg"><i></i><i></i><i></i></div>
    <div class="container">
        <div class="row">
            <div class="col-sm-5 counters set-height">
                <img src="assets/images/app_landing2/mobile.png" alt="" class="img-fluid mobile1">
                <img src="assets/images/app_landing2/j.png" alt="" class="img-fluid j-img">
            </div>
            <div class="col-sm-7 counters">
                <div class="abouts  center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before text-uppercase"><span>our progress</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header font-secondary">Find and Post Events</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="about-para">Discover exciting events happening near you and meet people who share
                                your interests. From local festivals to spontaneous gatherings, Hooked makes it easy to
                                connect with your community and create unforgettable experiences. Join the fun and
                                explore what's happening around you! Hosting an event? Post it on Hooked!</p>
                        </div>
                        <!--                        <ul class="icon-collection">-->
                        <!--                            <li class="about-icon">-->
                        <!--                                <a href="javascript:void(0)" class="center-content"><img-->
                        <!--                                        src="assets/images/app_landing2/about-icons/1.png" alt="" class="img-fluid"></a>-->
                        <!--                            </li>-->
                        <!--                            <li class="about-icon">-->
                        <!--                                <a href="javascript:void(0)" class="center-content"><img-->
                        <!--                                        src="assets/images/app_landing2/about-icons/2.png" alt="" class="img-fluid"></a>-->
                        <!--                            </li>-->
                        <!--                            <li class="about-icon">-->
                        <!--                                <a href="javascript:void(0)" class="center-content"><img-->
                        <!--                                        src="assets/images/app_landing2/about-icons/3.png" alt="" class="img-fluid"></a>-->
                        <!--                            </li>-->
                        <!--                        </ul>-->
                        <!--                        <a class="btn btn-default btn-gradient m-t-50">learn more</a>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--about end-->